.PinderPageContainer {
  padding-left: 16px;
  padding-right: 24px;
}
.cursorPointer {
  cursor: pointer;
}
.pageTitle {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  color: #323232;
}
.dropdownLabel {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  margin-right: 15px;
  color: #323232;
}
.dropdown {
  min-width: 132.8px;
  height: 32px;
}
.filterDropdown {
  width: 100%;
  height: 32px;
}
.dropdown1 {
  margin: 0 17px;
  min-width: 132.8px;
  height: 32px;
}
.filterState {
  width: 100%;
  height: 32px;
  margin-bottom: 10px;
}
.MultiSelect {
  width: 100%;
  display: flex;
  align-items: center;
}
.danger {
  border: 1px solid red !important;
}
.danger::placeholder {
  color: red;
}
.inputSwitch {
  width: 42px;
  height: 22px;
}
.dropdown > .p-dropdown-label,
.dropdown > .p-inputtext {
  font-size: 13px !important;
  color: #323232 !important;
}
.filterBox {
  width: 265px;
  padding: 16px 0 16px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01),
    0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01),
    0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02),
    0 19px 69px 0 rgba(0, 0, 0, 0.03);
}

.FilterBoxTitle {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
  color: #323232;
}
.searchInput {
  width: 88%;
  height: 32px !important;
  border-radius: 4px;
  margin: 0 auto !important;
  position: relative;
}
.pinderSearchInputBox {
  height: 32px !important;
}
.pinderSearchInputBox::placeholder {
  color: #999999;
  font-family: "Inter";
  font-size: 12px;
  padding-left: 3px;
}
.collapseTitle {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 600;
  color: #323232;
}
.checkboxLabel {
  font-family: "Inter";
  font-size: 14px !important;
  margin-left: 8px;
  position: absolute;
  top: 4px;
  color: #666666;
}
.activeCheckboxLabel {
  font-family: "Inter";
  font-size: 14px !important;
  margin-left: 8px;
  position: absolute;
  top: 4px;
  color: #000;
}
.PinderCheckbox {
  cursor: pointer;
}
.checkbox {
  position: relative;
}

.tick {
  position: absolute;
  left: 3px;
  top: 10px;
  width: 8px;
}
.PinderMultiSelect {
  width: 100%;
  margin-right: 2px;
  margin-left: 2px;
  height: 32px;
}
.PinderCalender {
  width: 48%;
  margin-right: 2px;
  margin-left: 2px;
  height: 32px;
}
.PinderRange {
  width: 100px !important;
  margin-left: 4px;
}
.pinderInput {
  margin-bottom: 10px;
}
.summaryBox {
  width: 440px;
  float: right;
  padding: 16px 16px 0px 16px;
  border-radius: 8px;
  box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01),
    0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01),
    0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02),
    0 19px 69px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
}
.height {
  height: 500px;
  overflow-y: scroll;
}

.summaryBox::-webkit-scrollbar {
  width: 10px;
}

.summaryBox::-webkit-scrollbar-track {
  background: #f3f4f8 !important;
}

.summaryBox::-webkit-scrollbar-thumb {
  min-height: 40px !important;
  border-radius: 30px !important;
  background-color: #666666 !important;
}
.summaryTitle {
  background-color: var(--main-blue);
  height: 45px;
  padding: 14px 16px;
  border-radius: 5px 5px 0 0;
}
.summaryTitle span {
  font-family: "Inter";
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.summaryItem {
  padding: 10px 16px;
}
.summaryItem span {
  font-family: "Inter";
  font-size: 13px;
  font-weight: bold;
  color: #323232;
}
.TablesContainer {
  position: relative;
}

.upDownArrow {
  width: 22px;
  height: 12px;
  color: #000;
  cursor: pointer;
}
.TablesContainerInside {
  overflow-x: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  max-width: 100%;
  padding-top: 35px;
}

.CollapseableTableContainer {
  position: absolute;
  right: 0;
  display: flex;
  overflow-x: hidden;
}
.MainTable {
  width: 100%;
  z-index: 1;
  border-collapse: collapse;
  table-layout: fixed;
  overflow-x: scroll !important;
}

.CollapseableTableContainer table:first-of-type {
  z-index: 2;
}
.CollapseableTableContainer table:last-of-type {
  z-index: 3;
}
.MainTable thead tr {
  background-color: #a9c8ff;
}
.bgGreen {
  background-color: #00a779;
}
.bgYellow {
  background-color: #fee468;
}
.bgYellow th {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 500;
  color: #323232;
}
.MainTable thead tr th,
.bgGreen th {
  color: #fff;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 500;
}
.MainTable tr,
.CollapseableTable tr {
  height: 72px;
}

.MainTable thead tr,
.CollapseableTable thead tr {
  border: 1px solid #fff;
}
.MainTable th,
.MainTable td {
  height: 64px !important;
  width: 200px;
  text-align: center;
}
.MainTable td,
.MainTable th,
.CollapseableTable td,
.CollapseableTable th {
  padding: 16px 5px;
}
.MainTable tbody tr {
  background-color: #e8f3ff;
  border: solid 1px #fff;
  color: #323232;
}
.CollapseableTable tbody tr {
  color: #323232;
}
.bgLightGreen {
  background-color: #ebfff9;
}
.bgLightYellow {
  background-color: #fffcf0;
}
.MainTable tbody td,
.CollapseableTable tbody td {
  font-family: "Inter";
  font-size: 13px;
  color: #323232;
}

.shrinkWidth {
  position: relative;
  right: -75px;
  transition: right 0.2s linear;
}
.shrinkWidth2 {
  position: relative;
  right: -170px;
  transition: right 0.2s linear;
}
.shrinkWidth3 {
  position: relative;
  right: -75px;
  transition: right 0.2s linear;
}
.shrinkWidth4 {
  position: relative;
  right: -95px;
  transition: right 0.2s linear;
}
.dataSheetIconContainer {
  background-color: var(--main-blue);
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #666666;
  width: 44px;
  height: 44px;
}
.dataSheetIconContainer img {
  margin-top: -4px;
}
.darkGreyText {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  color: #666666;
}
.TablesContainerInside::-webkit-scrollbar {
  display: none;
}
.navigationBtn {
  position: absolute;
  right: -7px;
  top: 56px;
  z-index: 1000;
}
.prevNavigationBtn {
  position: absolute;
  top: 60px;
  left: -7px;
  z-index: 1000;
  cursor: pointer;
  z-index: 2000;
  transform: rotate(180deg);
  filter: saturate(0%);
  opacity: 0.8;
}
.activeImg,
.activeImg {
  filter: saturate(100%) !important;
  opacity: 1 !important;
}
.navigationBtnImg,
.prevNavigationBtnImg {
  width: 22px;
}
.firstColumn {
  padding-left: 40px !important;
}
.TotalCount {
  font-size: 16px;
  color: #323232;
}
.columnHeading {
  position: relative;
}
