.sort{
  font-size: 13px;
}
.crossFilter{
font-size: 16px !important;
}
.searchSelect{
  border-top: 1px solid #ccc;
}
.searchFilter{
  margin: 16px 0px;
  width: 238px;
}
.ColumnFilterContainer {
  position: relative;
}
.ToggleFilterButton {
  font-size: 12px !important;
  margin: 0 10px;
}
.filter {
  width: 260px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 30px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01),
    0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01),
    0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02),
    0 19px 69px 0 rgba(0, 0, 0, 0.03);
  color: #323232;
  z-index: 30000;
}
.columnTitle {
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  color: #000;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 20px !important;
}
.FilterTitle {
  font-size: 14px;
  /* font-weight: bold; */
  /* text-align: left !important; */
  
}
.filter .button {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 7px;
}
.filter .button > div {
  cursor: pointer;
}
.button i {
  font-size: 13px !important;
  margin: 0 6px;
}

.filter .button div {
  border-radius: 5px;
  padding: 5px 15px 5px 5px;
}
.button div:hover {
  color: var(--main-blue);
}
.selected {
  color:  var(--main-blue);
}
.filter span {
  text-transform: none;
  list-style-type: none;
  padding: 2px 0px;
}
