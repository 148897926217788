.ProgressNavbarContainer {
  width: 90%;
  background-color: var(--white) !important;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04) !important;
  
  max-height: 145px;
  margin-left: .2%;
}
.stepCircle {
  width: 28px;
  height: 28px;
  border: 2px solid #cccccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: #cccccc;
  margin-top: -36px;
}
.stepCircleCompleted {
  background-color: var(--main-blue);
  border: 2px solid var(--main-blue);
}
.stepCircleActive {
  color: var(--main-blue);
  background-color: white;
  border: 2px solid var(--main-blue);
}
.hline {
  height: 2px;
  width: 17.8%;
  background-color: #cccccc;
  margin-top: -36px;
}
.hlineCompleted {
  height: 2px;
  width: 17.8%;
  background-color: var(--main-blue);
  margin-top: -36px;
}
.Shorthline {
  width: 17% !important;
}
@media (min-width: 2500px) {
  .hline {
    height: 2px;
    width: 18.2%;
    background-color: #cccccc;
  }
  .hlineCompleted {
    height: 2px;
    width: 18.2%;
    background-color: var(--main-blue);
  }
  .Shorthline {
    width: 19% !important;
  }
}
