body,
html {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

:root {
  --grey: #999999;
  --grey-light: #cccccc;
  --grey-dark: #323232;
  --grey-smooky: #e0e0e0;
  --light-blue: #ebf2fa;
  --main-blue: #0465eb;
  --secondary-blue: #e8f3ff;
  --primary-blue: #a9c8ff;
  --primary-green: #00a779;
  --secondary-green: #ebfff9;
  --primary-yellow: #fee468;
  --secondary-yellow: #fffcf0;
  --primary-red: #cd4a5e;
  --secondary-red: #faedef;
  --gredient-blue-1: #0565ea;
  --gredient-blue-2: #03c4fa;
  --gradient-blue-3: #01c4fa;
  --gradient-blue-4: #01baf8;
  --gradient-blue-5: #0269eb;
  --link-active-color: #2196f3;
  --solid-colors: var(--main-blue);
  --white: #fff;
  --text-color: #fff;
  --off-white: #fbfcfc;
  --font-family: "Inter";
  --heading-color: #059af4;
  --circleColor: "url(%23j7u62wk9da)";
  --goldColor:#BC9C2F;
  --arrowColor:#BC9C2F;
  --slider-fillColor:#2196F3;
  --bg-pdf-color:#3d66b0;
}
.p-slider .p-slider-range {
  background: var(--slider-fillColor);
}
.pi-chevron-down,.pi-chevron-up{
color:var(--arrowColor);
}
* {
  scroll-behavior: smooth;
}
body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f6f8 !important;
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
.p-tabview-nav-container {
  background-color: var(--white) !important;
  box-shadow: 0 1.5px 9.9px 0 rgb(11 61 126 / 0%),
    0 7.3px 38.9px 0 rgb(11 61 126 / 0%), 0 19px 122px 0 rgb(7 62 132 / 1%);
}
.p-card {
  border-radius: 10px;
}
.p-tabview .p-tabview-panels {
  padding: 0rem !important;
}
.notification-container {
  min-width: 320px !important;
  width: auto !important;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.minWidth {
  min-height: 75vh !important;
}
.checkbox {
  width: 20px;
  height: 20px;
  border: solid 1.5px var(--main-blue);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.checkbox i {
  color: var(--main-blue);
  font-size: 10px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: white !important;
  border: 2px solid var(--main-blue) !important;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: var(--main-blue) !important;
  font-size: 10px !important;
}
.p-inputtext {
  font-family: "Inter" !important;
}

.LineTd {
  position: relative;
}
.LineTd::before {
  content: "";
  position: absolute;
  top: 16px;
  left: -5px;
  width: 2.5px;
  height: 31px;

  background-color: var(--grey-smooky);
}
.p-tabview .p-tabview-panels {
  background: transparent !important;
}
.p-dropdown-panel {
  box-shadow: 0 0.2px 3.1px 0 rgb(0 0 0 / 0%), 0 1px 6.5px 0 rgb(0 0 0 / 1%),
    0 2.4px 13px 0 rgb(0 0 0 / 1%), 0 4.6px 25.5px 0 rgb(0 0 0 / 1%),
    0 7.8px 46.9px 0 rgb(0 0 0 / 2%), 0 3px 80px 0 rgb(0 0 0 / 1%) !important;
}
.p-toast-message {
  width: auto !important;
}
.p-toast .p-toast-message.p-toast-message-success {
  background-color: white;
  border: none;
  border-width: 0 0 0 6px;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-toast .p-toast-icon-close-icon {
  color: black;
}
.p-toast-message-content {
  display: flex;

  align-items: center;
}
.p-toast-top-right {
  top: 12px;
  right: 10px;
}
.p-toast .p-toast-message.p-toast-message-error {
  background-color: var(--primary-red) !important;
  border: none !important;
  color: white;
}
.p-toast .p-toast-message .p-toast-message-content {
  border-width: 0px;
  padding-left: 20px;
  padding-right: 15px;
}

.p-toast .p-toast-message-success .p-toast-message-content,
.p-toast .p-toast .p-toast-message-info .p-toast-message-content {
  padding: 0 !important;
}
.p-toast .p-toast-message-success,
.p-toast .p-toast-message-info {
  width: 625px !important;
  justify-content: center !important;
}
.p-toast .p-toast-message-warn {
  width: 307px !important;
  justify-content: center !important;
}
.p-toast .p-toast-message {
  border-radius: 4px !important;
  margin: 0 0 0 0 !important;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0px;
  margin-right: 10px;
}
.p-toast-message-icon {
  display: none !important;
}
.p-toast-icon-close-icon {
  color: white;
  background: transparent;
}
.p-toast {
  width: auto;
}
.p-toast-icon-close-icon:focus {
  background-color: none !important;
  border: none !important;
  outline: none !important;
}
.p-toast-icon-close-icon:hover {
  background-color: transparent !important;
}
.p-toast-icon-close {
  background-color: transparent !important;
}

.p-toast-icon-close:focus {
  background-color: transparent !important;
}
.p-toast-message-success .p-toast-message-content .p-toast-icon-close {
  display: none !important;
}
.p-toast-message-info .p-toast-message-content .p-toast-icon-close,
.p-toast-message-warn .p-toast-message-content .p-toast-icon-close {
  display: block !important;
  color: black !important;
}
.p-toast .p-toast-message.p-toast-message-info,
.p-toast .p-toast-message.p-toast-message-warn {
  border: none !important;
  background-color: white;
}
.popup-info-text {
  font-family: "Inter";
  font-size: 16px;
  font-weight: normal;
  color: #323232;
  margin-top: 25px;
  text-align: center;
}
.popup-warn-text {
  font-family: "Inter";
  font-size: 13px;
  font-weight: normal;
  color: #323232;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}
.p-toast-detail {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.p-toast-summary {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}

.toast-anchor {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.toast-yes {
  width: 89px;
  height: 35px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #0565ea, #03c4fa);
  color: white;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  margin-right: 24px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}
.toast-yes:hover {
  color: white;
}
.popup-success-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #323232;
  margin-top: 32px;
}
.popup-error-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #323232;
}
.toast-no {
  width: 83px;
  height: 35px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  border-width: 1px;
  border-image-source: linear-gradient(to right, #0565ea, #03c4fa);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #fff, #fff),
    linear-gradient(to right, #0565ea, #03c4fa);
  color: linear-gradient(to right, #0565ea, #03c4fa);
  cursor: pointer;
  text-decoration: none;
}
.p-input-icon-right .p-inputtext {
  height: 37px !important;
}
label {
  font-size: 14px !important;
}
.input-label {
  font-size: 14px !important;
  font-weight: bold !important;
}
.InputText {
  width: 320px !important;
  border: 1px solid #cccccc;
  border-radius: 4px !important;

  line-height: normal;
  letter-spacing: normal;

  font-family: "Inter" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  height: 36px;
  padding: 15px 0px 14px 16.8px;
  color: var(--black-main-color);
  overflow: hidden;
}
.InputText:focus {
  border-image-source: linear-gradient(
    to right,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 100%
  );
  border-style: solid;
  border-width: 1px;
  border-image-slice: 1;
  border-radius: 4px !important;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
}

.my-toolTip {
  background-color: green !important;
}
.p-dropdown-item {
  font-size: 12px !important;
}
.Remove-property-container {
  display: flex;
  text-align: right;
  margin-right: 1.5rem;
}
.Remove-property {
  font-size: 12px;
  color: #999999;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  font-family: "Inter";
}
.p-tabview .p-tabview-nav {
  margin-bottom: -20px;
}
.Equity-main-heading {
  font-size: 24px !important;
  font-weight: 500;
  color: var(--blackColor);
  font-family: "Inter";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 1rem;
  cursor: pointer;
}
.my-1 input {
  background: none !important;
}
.PDate::placeholder {
  text-transform: uppercase !important;
}

.rightBox {
  min-width: 227px;
  height: 441px;

  border-radius: 8px;
  background-image: linear-gradient(
    207deg,
    #01c4fa 100%,
    #01baf8 100%,
    #0269eb 0%
  );

  position: fixed;
}

.topProgess {
  background-color: orange;
  width: 120%;
  margin-right: 10px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.pe-outer {
  padding-right: 0px;
}
.EquitBox-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  position: relative;
  margin-top: 20px;
}
.boxRight-container {
  position: relative;
}
.boxRight {
  width: 230px;
  height: 441px;
  padding: 24px 16px 17px;
  padding-left: 17px !important;
  border-radius: 8px;
  background: linear-gradient(
    20deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 100%
  ) !important;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  position: -webkit-sticky;
}
.boxRight .mainheading {
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #fff;
  margin-bottom: 24px !important;
}
.boxRight div .subtxt {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.boxRight div .maintxt {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.boxRight .box1 {
  margin-bottom: 24px !important;
}
.boxRight .box2 {
  margin-bottom: 48px !important;
}
.whiteBox {
  margin: 48px 0 0;
  padding: 9px 30px 17px 31px;
  border-radius: 4px;
  background-color: #fff;
  height: 71px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.whiteBox .sub {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  color: var(--main-blue);
}
.whiteBox .main {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 9px;
  color: var(--main-blue);
}
.boxLeft {
  background-color: orange;
  width: 70%;
  margin-right: 10px;
  height: 1500px;
}
.row1 {
  display: flex;
  flex-direction: row;
}
.col1 {
  margin-right: 10px;
  width: 70%;
}
.col2 {
  position: relative;
  padding-bottom: 30px;
}
.box-right-container {
  position: relative;
  margin-top: 20px;
}
.dropDown-container .p-focus,
.dropDown-container1 .p-focus {
  border: 1px solid #2196f3 !important;
}
.donut-graph {
  padding-left: 10px;
  padding-right: 10px;
}
.p-tabview-nav-container {
  margin-left: 5px;
}
.p-tabview-nav-container {
  background-color: transparent !important;
}
.p-tabview .p-tabview-nav {
  background-color: transparent !important;
}
.p-tabview-nav {
  background-color: transparent !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent !important;
}
.p-tabview-panels {
  margin-top: 30px;
}
.hideBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 5px;
  height: 200px;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 37px;
  box-shadow: 0px 2px 10px rgb(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 10;
  padding-left: 10px;
}
.hideBox1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 5px;
  height: 200px;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 62px;
  box-shadow: 0px 2px 10px rgb(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 10;
  padding-left: 10px;
}

.pinder.pi-search::before {
  font-size: 14px !important;
  position: absolute;
  top: 1px;
  left: 3px;
  color: #222222;
}
.pinderContainer .card-body {
  padding-bottom: 0 !important;
}
.pinderCheckbox .p-checkbox-box {
  width: 14px !important;
  height: 14px !important;
}
.hideBox p,
.hideBox1 p {
  font-size: 13px;
  font-family: "Inter";
  cursor: pointer;
  z-index: 100;

  position: relative;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f3f4f8 !important;
}

*::-webkit-scrollbar-thumb {
  min-height: 40px !important;
  border-radius: 30px !important;
  background-color: #666666 !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  left: 0.095rem !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: var(--link-active-color);
}
.p-multiselect-panel {
  max-width: 248px !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--main-blue) !important
}