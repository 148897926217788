.total-text small {
  font-size: 11px !important;
}
.popicon {
  font-family: Poppins;
  font-size: 20px !important;
}
.property-price-text {
  z-index: 0;
  color: #3d66b0;
  font-size: 13.5px !important;
  font-family: Poppins;
}
.price-text {
  color: var(--bg-pdf-color);
  font-size: 15px !important;
  font-family: Poppins;
}
.client-restrictions {
  background: #666666 !important;
  display: flex;
  justify-content: center !important;
  border-radius: none !important;
}
.restrictions-text {
  font-size: 20px;
  font-weight: 500;
  color: white !important;
}
.total-text {
  font-family: Poppins;
  color: #131d48;
  font-size: 15px;
}
.property-total-text {
  font-family: Poppins;
  color: #131d48;
  font-size: 15px;
}
.header1 {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;

  flex-direction: row;
}
.header1 div:first-child {
  width: 50.5%;
}
.header1 div:nth-child(2) {
  width: 50.3%;
  height: 280%;
  background-color: #ecf3ff;
  text-align: right;
  margin-right: 4px;
}

.firstProperty {
  background-color: var(--white);
  height: 1150px;
}

.firstProperty h3 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131d48;
}

.firstProperty #active {
  background-color: var(--bg-pdf-color);

  border: 1px solid var(--bg-pdf-color);
  padding: 8px 10px;
  font-weight: 600;
  opacity: 1;
}
.firstProperty .list-group-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--bg-pdf-color);
  border-right: none;
  border-left: none;
  padding: 10px 3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  letter-spacing: normal;
}

.firstProperty .col2 .li1 .list-group-item:nth-child(2) {
  background-color: #f6f6f6;
}

.firstProperty .col2 .li1 .list-group-item:nth-child(2) {
  background-color: #f6f6f6;
}
.firstProperty .col2 .li1 .list-group-item:nth-child(8) {
  background-color: #f6f6f6;
}
.firstProperty .col2 .li1 .list-group-item:nth-child(7) {
  background-color: #aee8ff;
}
.firstProperty .col2 .li2 .list-group-item:nth-child(5) {
  background-color: #aee8ff;
}
.firstProperty .col2 .li2 .list-group-item:nth-child(6) {
  background-color: #f6f6f6;
}
.firstProperty .col2 .li2 .list-group-item:nth-child(7) {
  background-color: #ffffff;
}

.firstProperty .col2 .list-group-item {
  background-color: #e0f6ff;
  border-bottom: none;
}

.firstProperty .list-group-item div:nth-child(1) {
  color: var(--dark-slate-blue);
  text-align: left;
}
.firstProperty .list-group {
  padding-bottom: 10px;
}
.firstProperty .list-group-item:last-child {
  border-radius: 0px;
}

.firstProperty .list-group-item div:nth-child(2) {
  font-size: 16px;
  text-align: right;
  color: #3d66b0;
}

.portfolioMetrics {
  background-color: #ecf3ff;
}

.portfolioMetrics h3 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131d48;
  margin: 0% 5% 3% 14%;
}

.portfolioMetrics .list-group {
  padding-bottom: 70px;
}
.portfolioMetrics .list-group-item {
  margin-left: 15%;
  width: 80%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3d66b0;
  border-right: none;
  border-left: none;
  border-top: none;
  padding: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.portfolioMetrics .list-group-item:last-child {
  border-radius: 0px;
}

.portfolio-design .coverimg {
  width: 90%;
  object-fit: cover;
}

.portfolio-design {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  margin-left: 1%;
}

.header2 {
  width: 100%;
  background-color: #fff;
}
.header2 img {
  background-color: black;
}

.header2 span {
  padding: 15px;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #be9e30;
}

.property-cards {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.property-card {
  border-radius: 19px !important;
  width: 100%;
  background-color: #fff;
  padding: 12px 12px 0px 12px;
  border-left: 1px solid #d6c5c5 !important;
  border-right: 1px solid #d6c5c5 !important;
  border-top: 1px solid #d6c5c5 !important;
  border-bottom: 1px solid #d6c5c5 !important;
}
.property-card .imgContainer {
  position: relative;
  margin-bottom: 30px;
}
.property-card .figCaption {
  position: absolute;
  left: 0;
  top: 0;
}
.property-card .figCaption figcaption {
  padding: 18px 18px;
  background-color: var(--white);
  white-space: nowrap;
  color: #131d48;
  font-size: 18px;
  font-weight: 600;
}

.property-card img {
  object-fit: cover;
  display: block;
  margin-right: auto;
  width: 100%;
}
.property-card .card-body {
  background-color: #fff;
}

.property-card #active {
  background-color: var(--bg-pdf-color);
  border: 1px solid var(--bg-pdf-color);
  padding: 15px;
  font-weight: 600;
  opacity: 1;
}
.property-card .card-body h3 {
  margin-bottom: 80px;
  padding-bottom: -30px;
  font-size: 32px;
  font-weight: 500;
  transform: translate(0%, -120%);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  max-height: 30px;
  letter-spacing: normal;
  text-align: left;
  margin: 0px;
  color: var(--dark-slate-blue);
  padding-left: 0px;
}
.property-card .list-group-flush {
  transform: translate(0%, -10%);
  padding: 10px 10px 0px 10px;
  padding-bottom: 0;
  border: none !important;
  background-color: "white";
}
.property-card .list-group-item {
  border-bottom: 1px solid #3d66b0;
  display: flex;
  background-color: "white";
  justify-content: space-between;
  border-top: none;
  padding: 15px;
  opacity: 0.85;
  font-size: 16px;
  font-weight: 500;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.property-card .risk-5 {
  background-color: #ebfff9;
  color: #00a779;
}
.property-card .risk-6 {
  background-color: #fffcf0;
  color: #fee468;
}
.property-card .risk-7 {
  background-color: #fff3f5;
  color: #cd4a5e;
}

.property-cards .property-card .list-group-item:last-child {
  border-bottom: 1px solid #3d66b0;
  border-radius: 0;
}

.tcolor {
  color: #666666 !important;
}
.hide {
  visibility: hidden;
}
.restrictions-card {
  z-index: 999;
  background: #666666 !important;
  display: flex;
  justify-content: center !important;
  border-radius: none !important;
}
