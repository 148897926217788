.ColumnYieldBoxContainer {
  position: relative;
}
.ToggleYieldBoxButton {
  font-size: 12px !important;
  margin: 0 10px;
  user-select: none;
}
.YieldBox {
  min-width: 210px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 30px;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01),
    0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01),
    0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02),
    0 19px 69px 0 rgba(0, 0, 0, 0.03);
  color: #323232;
  z-index: 20000;
}
.columnTitle {
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  color: #323232;
  /* font-weight: bold; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 10px; */
}
.YieldBoxTitle {
  font-size: 14px;
  /* font-weight: bold; */
  text-align: left;
}
.YieldBox .button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.YieldBox .button > div {
  cursor: pointer;
}
.button i {
  font-size: 16px !important;
  margin: 0 6px;
}

.YieldBox .button div {
  border-radius: 5px;
  padding: 5px 15px 5px 5px;
}
.button:hover {
  color: var(--main-blue);
}
.selected {
  color: var(--main-blue);
}
.YieldBox span {
  text-transform: none;
  list-style-type: none;
  padding: 5px 0px;
}

.InputValue {
  max-width: 101px !important;
  max-height: 32px !important;
  margin-top: -5px;
}
