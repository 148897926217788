:root {
  --percentage: 0;
}
.title-container {
  height: 143px;
}
.page-title {
  font-size: 24px;

  font-weight: 500;
}
.page-desc {
  font-size: 20px;
  font-weight: normal;
  color: black !important;
}

.donut-graph {
  width: 12%;
  height: 143px;
  border-radius: 8px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);
  position: relative;
  opacity: 0.5;
  cursor: pointer;
}
.donut-graph:hover {
  opacity: 1;
}
.semi-donut {
  width: 100%;
  height: 50%;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  color: #1a2b56;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: flex-end;
  box-sizing: border-box;
}
.year-desc {
  font-size: 12px;
  font-weight: 500;
  color: #1a2b56;
}
.separator {
  width: 90%;
  height: 1px;
  margin: 6px 10px 13px 10px;
  border-radius: 12px;
  background-color: var(--grey-smooky);
}
.indicators {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;

  font-size: 11px;
  font-weight: 500;
  color: #cccccc;
  position: absolute;
  bottom: 34px;
}

.semi-donut:after {
  content: "";
  width: 90%;
  height: 200%;
  border: 8px solid;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) #bae7e5#bae7e5;
  position: absolute;
  border-radius: 50%;
  left: 5%;
  top: 0;
  box-sizing: border-box;

  animation: fillAnimation 1s ease-in;
}
.p-tabview-nav {
  min-height: 70px;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  border-radius: 8px;
  background-color: var(--white) !important;
  border: none !important;
  top: -10px;
  position: relative;
}

.p-tabview-nav-link {
  border: none !important;
  background-color: var(--white) !important;
  font-weight: normal !important;
}
.p-tabview-nav-link:focus {
  font-weight: 500 !important;
  box-shadow: none !important;
  color: var(--solid-colors) !important;
}
.highlighted-tab {
  color: var(--solid-colors) !important;
}
.p-tabview .p-tabview-panels {
  background-color: none !important;
}

.tabs li {
  margin-left: -12px;
  min-width: 18%;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.tabs li span {
  /* font-size: 10px  !important; */
  font-weight: bold;
}

.tabs .p-tabview-nav-container {
  margin-top: -73px;
}
.ss {
  width: 70vw;
}

@keyframes fillAnimation {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(135deg);
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .tabs li {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .ss {
    width: 76vw;
  }
  .tabs li span {
    font-size: 12px !important;
    font-weight: bold;
  }
}

@media (min-width: 960px) and (max-width: 1300px) {
  .ss {
    width: 75.5vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85.1%;
  }
  .tabs li {
    max-width: 22%;
  }

  .tabs li span {
    font-size: 12.1px !important;
  }
  .semi-donut {
    font-size: 9px !important;
  }
}
@media (min-width: 1300px) and (max-width: 1364px) {
  .ss {
    width: 78.5vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85.1%;
  }
}
@media (min-width: 1365px) and (max-width: 1439px) {
  .ss {
    width: 78.5vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85.1%;
  }
}
@media (min-width: 1440px) and (max-width: 1600px) {
  .ss {
    width: 80.5vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85%;
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .ss {
    width: 82.1vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85%;
  }
}

@media (min-width: 1701px) and (max-width: 1820px) {
  .ss {
    width: 83.4vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85%;
  }
}
@media (min-width: 1821px) and (max-width: 1920px) {
  .ss {
    width: 83.8vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85%;
  }
}

@media (min-width: 1920px) and (max-width: 2048px) {
  .ss {
    width: 84.8vw;
  }
  .tabs .p-tabview-nav-container {
    width: 85%;
  }
}

@media (min-width: 2049px) and (max-width: 2560px) {
  .ss {
    width: 86.3vw;
  }

  .tabs li {
    min-width: 17.8%;
    margin-left: 0px;
  }
  .tabs .p-tabview-nav-container {
    width: 86%;
  }
}
@media (min-width: 2560px) and (max-width: 3000px) {
  .ss {
    width: 89.3vw;
  }

  .tabs li {
    min-width: 17%;
    margin-left: 0px;
  }
  .tabs .p-tabview-nav-container {
    width: 86%;
  }
}

@media (min-width: 3001px) and (max-width: 5000px) {
  .ss {
    width: 92.5vw;
  }
  .tabs .p-tabview-nav-container {
    width: 88.55%;
  }
}

@media (min-width: 5001px) and (max-width: 6000px) {
  .ss {
    width: 95vw;
  }
}
@media (max-width: 1550px) {
  .semi-donut {
    height: 43%;
  }
}
@media (max-width: 1400px) {
  .semi-donut {
    font-size: 12px;
  }
}
@media (min-width: 2000px) {
  .semi-donut {
    width: 170px;
  }
}
