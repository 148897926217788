.sliders .p-slider-horizontal,
.sliders .p-inputtext {
  width: 14rem;
}

.sliders .p-slider-vertical {
  height: 14rem;
}

.w-60 {
  width: 70% !important;
  margin-bottom: 1rem !important;
}

.propertygenerator td:last-child {
  border-left: 2px solid black;
}
p {
  /* margin-top: 0; */
  margin-bottom: 0.1rem !important;
}
.col-fixed {
  overflow-wrap: anyware !important;
}
.p-inputtext {
  width: 100%;
}
.boxs-container {
 
  position: relative;
}
.boxs {
  padding-left: 0.9rem !important;
}
.boxs .col {
  border: 1px solid var(--white);
  background: var(--white);
  box-shadow: 0 1.5px 9.8px 0 rgba(11, 61, 126, 0.02),
    0 7.2px 38.2px 0 rgba(11, 61, 126, 0.02),
    0 18.7px 120px 0 rgba(7, 62, 132, 0.04);
  border-radius: 8.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px 9px 25px 0px;
  height: 135px;
  width: 21.5%;
}
.boxs .grid {
  gap: 1.3% !important;
  z-index: 200;
}
.grid.fixed {
  position: sticky;
  top: 0;
  background-color: #f1f4f8;
}
.pscroll {
  overflow-y: scroll;
  height: 61vh;
}

.boxs .col p {
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  color: var(--main-blue);
  text-align: center;
  padding: 0px 191.5px;
}
.boxs .col p:first-child {
  font-size: 13px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0.8rem !important;
}

.heading {
  margin: 0 0px 24px 0 !important;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-dark);
}
.heading2 {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #059af4;
}
.propertytable {
  width: 100%;
}
.propertytable tr {
  border-bottom: 0.6px solid rgba(79, 79, 79, 0.3);
}
.propertytable td,
.propertytable th {
  padding: 24px 0 24px 0 !important;
}
.propertytable td {
  text-align: right;
  font-weight: 600;
}
.propertytable td:first-child {
  text-align: left;
  font-weight: normal;
  font-size: 16px;
}
.total {
  margin-left: 89px !important;
  font-size: 16px;
  font-weight: 600;
  color: black;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.lbl {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-dark);
}
.radiomargin {
  margin-right: 40px;
  margin-top: 16px;
}

.notice {
  margin: 10px 0px 20px 0px !important;
  text-align: center;
}

.fixed-percentage {
  font-family: Inter;
  font-size: 11px;
  font-weight: normal;
  color: var(--grey-dark);
  margin: 0px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.fixed-percentage::before {
  content: "\2022";
  color: var(--main-blue);
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  margin-top: -3px;
  width: 1em;
}
.inpt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 2rem 1.5rem 2rem 1.5rem;
}

@media only screen and (max-width: 2625px) {
  .boxs .grid {
    gap: 2.7% !important;
  }
}
